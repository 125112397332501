import React from 'react';
import { useInView } from "react-intersection-observer";
import styled from 'styled-components';
import KeyNumber from './KeyNumber';

const Wrapper = styled.div`
    color: white;
    grid-column: 2/3;
    z-index: 2;
    &:not(.anim){
        .title-label {
            transform: translateY(50px);
            opacity: 0;
        }
    }
    .title-label {
        text-align: center;
        transition: all 400ms ease-in-out;
    }
    .numberList {
        display: flex;
        flex-wrap: wrap;
        margin-top: 30px;
        .numberItem {
            min-width: 50%;
            max-width: 50%;
        }
        .numberItem:not(:last-child) {
            margin-bottom: 40px;
        }
    }
    @media screen and (min-width: 800px) {
        grid-column: 3/13;
        .numberList {
            margin-top: 50px;
            flex-wrap: nowrap;
            justify-content: space-evenly;
            .numberItem {
                flex: 1 1 0;
                min-width: unset;
                max-width: unset;
            }
        }
    }
`;

const KeyNumbers = ({ className, data }) => {
    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0,
        triggerOnce: true
    });

    return (
        <Wrapper className={`${className} ct ${inView ? "anim" : ""}`} ref={ref}>
            <h4 className="title-label">{data.label}</h4>
            <ul className="numberList">
                {data.list_number.map((number) => (
                    <li className="numberItem" key={number.legend}>
                        <KeyNumber number={number.number} label={number.legend} />
                    </li>
                ))}
            </ul>
        </Wrapper>
    )
};

export default KeyNumbers;