import { Link } from 'gatsby';
import React, { useEffect, useRef } from 'react';
import Flickity from 'react-flickity-component';
import { useInView } from "react-intersection-observer";
import styled from 'styled-components';
import SimpleArrowLeft from '../../svg/simple-arrow-left.inline.svg';
import SimpleArrowRight from '../../svg/simple-arrow-right.inline.svg';
import useViewport from '../../utils/useViewport';
import ArrowButton from './ArrowButton';
import ProgressBar from './ProgressBar';
import SimpleButton from './SimpleButton';

const Wrapper = styled.div`
    color: white;
    grid-column: 2/3;
    width: 100%;
    z-index: 2;
    &:not(.anim){
        .title-label{
            opacity: 0;
            transform: translateY(50px);
        }
        .li-anim{
            &:after{
                height: 0;
            }
            .text-anim{
                transform: translateY(50px);
                opacity: 0;
            }
        }
    }
    .li-anim {
        &:after{
            transition: height 600ms ease-in-out 300ms;
        }
        .text-anim{
            transition: all 400ms ease-in-out 300ms;
        }
    }
    .title-label {
        text-align: center;
        transition: all 400ms ease-in-out;
    }
    .pushList {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        overflow: hidden;
        width: 80%;
        margin-top: 30px;
        ul {
            width: 100%;
            font-family: 'Unna';
            li {
                width: 100%;
                .topSection {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .navigationCount {
                        font-weight: bold;
                        font-style: italic;
                        .currentIndex {
                            font-size: 5rem;
                        }
                        .totalIndex {
                            font-size: 2rem;
                        }
                    }
                    .button {
                        margin-right: 10%;
                    }
                }
            }
            .title {
                margin-top: 30px;
                font-size: 2rem;
                line-height: 1.4;
            }
        }
        .navigation {
            margin-top: 30px;
            display: flex;
            justify-content: space-between;
            .buttonPrev {
                margin-right: 20px;
            }
            .buttonNext {
                margin-left: 20px;
            }
            .buttonPrev,
            .buttonNext {
                width: 11px;
                svg {
                    width: 100%;
                }
            }
        }
    }
    .link {
        margin-top: 36px;
    }
    @media screen and (min-width: 800px) {
        grid-column: 4/12;
        .pushList {
            width: 100%;
            margin-top: 70px;
            ul {
                display: flex;
                .totalIndex {
                    display: none;
                }
                li:not(:last-child) {
                    position: relative;
                    margin-right: 4%;
                    &:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: -4%;
                        height: 100%;
                        width: 1px;
                        background: white;
                        opacity: 0.3;
                    }
                }
            }
        }
        .link {
            margin-top: 50px;
        }
    }
`;

const PushList = ({ data, className, lang }) => {
    const { width } = useViewport();
    const breakpoint = 800;

    let flickity = useRef(null);
    let progressBar = useRef(null);

    function prevSlide() {
        flickity.previous();
        autoNext();
    }

    function nextSlide() {
        flickity.next();
        autoNext();
    }

    let autoNextTimeout;
    function autoNext() {
        clearTimeout(autoNextTimeout);
        autoNextTimeout = setTimeout(() => {
            if (flickity.current !== null) {
                nextSlide();
            }
        }, 5000);
    }

    useEffect(() => {
        if (
            flickity.current !== null &&
            width < breakpoint &&
            progressBar.current
        ) {
            flickity.on('change', (index) => {
                progressBar.current.progressChange(
                    ((index + 1) / data.array_push.length) * 100,
                );
            });
            autoNext();

            return () => {
                clearTimeout(autoNextTimeout);
            };
        }
    }, [width]);

    //Add link anchor
    let links;
    if (lang === "FR") {
        links = [
            "/fr/gestion-des-actifs#asset-allocation",
            "/fr/gestion-des-actifs#private-debt",
            "/fr/gestion-des-actifs#direct-investments",
            "/fr/gestion-des-actifs#cryptocurrency-arbitrage-fund"
        ]
    }
    else {
        links = [
            "/asset-management#asset-allocation",
            "/asset-management#private-debt",
            "/asset-management#direct-investments",
            "/asset-management#cryptocurrency-arbitrage-fund"
        ]
    }

    data.array_push.map((item, i) => {
        item['link'] = links[i];
    });

    const renderList = () =>
        data.array_push.map((push, i) => (
            <li key={push.title} className="li-anim">
                <div className="topSection">
                    <div className="navigationCount">
                        <span className="currentIndex text-anim">
                            {i > 10 ? i + 1 : '0' + (i + 1)}
                        </span>
                        <span className="totalIndex">
                            -{' '}
                            {data.array_push.length > 10
                                ? data.array_push.length
                                : '0' + data.array_push.length}
                        </span>
                    </div>
                    <Link className="button" to={push.link}>
                        <ArrowButton type="gradient" />
                    </Link>
                </div>
                <p className="title text-anim">{push.title}</p>
            </li>
        ));

    const renderMobile = () => {
        const options = {
            wrapAround: true,
            prevNextButtons: false,
            selectedAttraction: 0.012,
            friction: 0.28,
        };
        return (
            <>
                <Flickity
                    flickityRef={(c) => (flickity = c)}
                    elementType={'ul'}
                    className="carroussel"
                    options={options}
                >
                    {renderList()}
                </Flickity>
                <div className="navigation">
                    <div onClick={prevSlide} className="buttonPrev">
                        <SimpleArrowLeft />
                    </div>
                    <ProgressBar
                        className="progressBar"
                        ref={progressBar}
                        initValue={(1 / data.array_push.length) * 100}
                    />
                    <div onClick={nextSlide} className="buttonNext">
                        <SimpleArrowRight />
                    </div>
                </div>
            </>
        );
    };

    const renderDesktop = () => <ul>{renderList()}</ul>;

    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0,
        triggerOnce: true
    });

    return (
        <Wrapper className={`${className} ct ${inView ? "anim" : ""}`} ref={ref}>
            <h4 className="title-label">{data.label}</h4>
            <div className="pushList">
                {width < breakpoint ? renderMobile() : renderDesktop()}
            </div>
            <SimpleButton className="link" data={data.link} />
        </Wrapper>
    );
};

export default PushList;
