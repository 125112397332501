import React from 'react';
import { Parallax } from "react-scroll-parallax";
import styled from 'styled-components';
import colors from '../../styles/colors.js';

const Wrapper = styled.div`
    grid-column: 1/5;
    position: relative;
    .inner {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            bottom: 0;
            left: 0;
        }
        .gradient {
            width: 100%;
            height: 90%;
            position: absolute;
            top: -1px;
            z-index: 1;
            background: linear-gradient(
                to bottom,
                ${colors.dark_blue} 1%,
                ${colors.transparent_dark_blue} 50%
            );
        }
    }
    @media screen and (min-width: 800px) {
        grid-column: 1/15;
        .inner {
            height: 100vh;
            width: 100%;
        }
    }
`;

const FooterMountains = ({ className }) => {
    return (
        <Wrapper className={className}>
            <Parallax y={[-15, 15]}>
                <div className="inner">
                    <div className="gradient"></div>
                    <picture>
                        <source
                            media="(min-width: 800px)"
                            srcSet="/images/footer-desktop.jpg"
                        />
                        <img src="/images/footer-mobile.jpg" alt="mountain" />
                    </picture>
                </div>
            </Parallax>
        </Wrapper>
    )
};

export default FooterMountains;
