import React, { Fragment } from 'react';
import { useInView } from "react-intersection-observer";
import styled from 'styled-components';

const Wrapper = styled.div`
    color: white;
    padding: 0 15%;
    &:before {
        content: '';
        position: absolute;
        width: 70px;
        height: 70px;
        border-radius: 100%;
        border: 1px solid rgba(255, 255, 255, 0.5);
        mask-image: linear-gradient(-45deg, transparent 40%, black 100%);
        mask-repeat: no-repeat;
        pointer-events: none;
        transition: opacity 400ms ease-in-out 200ms;
    }
    &:not(.anim){
        .number{
            .container-letter{
                .letter{
                    transform: translateY(100%);
                }
            }
        }
        &:before{
            opacity: 0;
        }
    }
    .number {
        padding-top: 16px;
        width: 80px;
        text-align: center;
        font-family: 'Unna';
        font-weight: bold;
        font-style: italic;
        font-size: 5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        
        .container-letter{
            overflow: hidden;
            display: inline-block;
            .letter{
                display: block;
                transition: transform 400ms ease-in-out;
            }
            
        }
    }
    .label {
        padding-left: 16px;
        font-family: 'Heebo';
        font-weight: 300;
        font-size: 1.4rem;
        line-height: 1.7;
    }
    @media screen and (min-width: 800px) {
        &:before {
            width: 90px;
            height: 90px;
            border-width: 2px;
        }
        .number {
            font-size: 9rem;
            width: 130px;
        }
        .label {
            font-size: 1.8rem;
            line-height: 1.56;
        }
    }
`;

const KeyNumber = ({ className, number, label }) => {
    let itemNumber = number.split("");
    const numberSplit = () => {
        return (
            <Fragment>
                {itemNumber.map((item, index) => (
                    <span key={index} className={"container-letter letter-" + index}>
                        <span className="letter">
                            {item}
                        </span>
                    </span>
                ))}
            </Fragment>
        )
    }
    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0,
        triggerOnce: true
    });
    return (
        <Wrapper className={inView ? "anim" : ""} ref={ref}>
            <p className="number">
                {numberSplit()}
            </p>
            <p className="label">{label}</p>
        </Wrapper>
    )
};

export default KeyNumber;
