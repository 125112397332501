import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    position: relative;
    margin-top: 4.5px;
    flex-shrink: 4;
    width: 100%;
    height: 2px;
    background: rgba(255, 255, 255, 0.3);
    .completion {
        transition: all 600ms ease-out;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background: white;
        width: ${(props) => props.initValue + '%'};
    }
`;

const ProgressBar = forwardRef((props, ref) => {
    let completion = useRef(null);

    function progressChange(percentage) {
        completion.current.style.width = percentage + '%';
    }

    useImperativeHandle(ref, () => {
        return {
            progressChange: progressChange,
        };
    });

    return (
        <Wrapper className={props.className} {...props}>
            <div ref={completion} className="completion"></div>
        </Wrapper>
    );
});

export default ProgressBar;
