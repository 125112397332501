import React from 'react';
import styled from 'styled-components';
import colors from '../../styles/colors.js';
import SimpleButton from './SimpleButton';
import {useInView} from "react-intersection-observer";
import {Parallax} from "react-scroll-parallax";

const Wrapper = styled.div`
    color: white;
    grid-column: 2/3;
    text-align: center;
    z-index: 2;
    
    &:not(.anim){
        .text-anim{
            transform: translateY(50px);
            opacity: 0;
        }
    }
    .text-anim{
        transition: all 500ms ease-in-out;
    }
    h3 {
        margin-top: 28px;
    }
    .button {
        margin-top: 50px;
    }
    h4 {
        z-index: 2;
        color: ${colors.pink};
    }
    @media screen and (min-width: 800px) {
        grid-column: 5/11;
        .button {
            margin-top: 80px;
        }
    }
`;

const Text = ({ data, className }) => {
    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0,
        triggerOnce: true
    });

    return (
        <Wrapper
            className={`${className} ct ${inView ? "anim" : ""}`}
            ref={ref}>
            { data.label && (
                <Parallax y={[-20, 20]}>
                    <h4 className="title-label text-anim">{data.label}
                    </h4>
                </Parallax>)}
            <Parallax y={[-20, 20]}>
                <h3 className="title-3 text-anim">{data.text}</h3>
            </Parallax>
            {(data.link.url || data.link.page) && (
                <SimpleButton className="button" data={data.link} />
            )}
        </Wrapper>
    );
};

export default Text;
