import React from 'react';
import styled from 'styled-components';
import ArrowButton from './ArrowButton';
import LinkHandler from './LinkHandler';

const Wrapper = styled.div`
    color: white;
    grid-column: 2/3;
    z-index: 2;
    margin-bottom: 120px;
    .title-label {
        text-align: center;
    }
    .link {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 40px auto 0 auto;
        width: fit-content;
        p {
            margin-right: 20px;
        }
    }
    @media screen and (min-width: 800px) {
        grid-column: 4/12;
    }
`;

const Push = ({ className, data }) => (
    <Wrapper className={`${className} ct`}>
        <h4 className="title-label">{data.label}</h4>
        <LinkHandler link={data.link} className="link">
            <p className="title-1">{data.link.label}</p>
            <ArrowButton type="gradient" />
        </LinkHandler>
    </Wrapper>
);

export default Push;
